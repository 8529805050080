import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Home } from 'app/home/Home';
import { Login } from 'app/login/Login';
import { LogoutContainer } from 'app/logout/LogoutContainer';
import { Strava } from 'app/strava/Strava';

import { AuthorizedRoute } from './AuthorizedRoute';
import { AppRoute } from './AppRoute.enum';
import { UnauthorizedRoute } from './UnauthorizedRoute';

export const AppRoutes = () => {
  return (
    <Switch>
      <AuthorizedRoute exact path={AppRoute.home} component={Home} />

      <UnauthorizedRoute path={AppRoute.login} component={Login} />
      <Route path={AppRoute.logout} component={LogoutContainer} />
      <Route path={AppRoute.strava} component={Strava} />

      <Redirect to={AppRoute.login} />
    </Switch>
  );
};
