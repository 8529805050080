import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import decode from 'jwt-decode';
import { parse } from 'qs';
import { useIsAuthenticated } from '@azure/msal-react';

import { registerAction } from 'api/actions/auth/authActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { Loader } from 'ui/loader/Loader';
import { AppRoute } from 'routing/AppRoute.enum';

export const Strava = () => {
  const { accessToken } = useAuthState();
  const isAuthenticated = useIsAuthenticated();
  const { search } = useLocation();
  const { code } = parse(search, { ignoreQueryPrefix: true }) as { code: string };
  const { mutate } = useMutation(registerAction);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (code && accessToken && isAuthenticated) {
      const { unique_name } = decode(accessToken) as { unique_name: string };

      mutate({ code, email: unique_name }).then(({ error }) => {
        if (!error) {
          setRedirect(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!redirect) {
    return <Loader />;
  }

  if (!code) {
    return <Redirect to={AppRoute.login} />;
  }

  return <Redirect to={AppRoute.home} />;
};
