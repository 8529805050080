import { Action } from 'api/types';

import { AuthorizeResponse, RefreshTokenResponse, RegisterResponse } from './authActions.types';

export function refreshTokenAction(access_token: string, refresh_token: string): Action<RefreshTokenResponse> {
  return {
    method: 'POST',
    endpoint: `${process.env.REACT_APP_KEYCLOAK_API_URL}/token`,
    body: {
      access_token,
      refresh_token,
      grant_type: 'refresh_token',
    },
    config: {
      skipAuthorization: true,
    },
  };
}

export function registerAction(body: { code: string; email: string }): Action<RegisterResponse> {
  return {
    method: 'POST',
    endpoint: '/register',
    body,
  };
}

export function loginAction(code: string): Action<AuthorizeResponse> {
  const params = new URLSearchParams();
  params.append('code', code);
  params.append('redirect_uri', `${window.location.origin}/keycloak`);
  params.append('grant_type', 'authorization_code');
  params.append('client_id', process.env.REACT_APP_KEYCLOAK_PUBLIC_ID || 'rad-security-staging-public');
  params.append('scope', 'openid');

  return {
    method: 'POST',
    endpoint: `${process.env.REACT_APP_KEYCLOAK_API_URL}/token`,
    body: params,
    config: {
      skipAuthorization: true,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
}
