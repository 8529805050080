import React from 'react';

import * as Styled from './ExternalLink.styles';

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
  className?: string;
};

export const ExternalLink = ({ children, ...props }: ExternalLinkProps) => {
  return (
    <Styled.Link {...props} rel="noreferrer nofollow noopener">
      {children}
    </Styled.Link>
  );
};
