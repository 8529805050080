import React from 'react';
import styled from 'styled-components';

import { Typography, TypographyProps } from 'ui/typography/Typography';
import * as colors from 'ui/theme/colors';

export const HeaderLabel = styled(({ children, ...props }: TypographyProps) => (
  <Typography {...props} color="white" weight="semiBold">
    {children}
  </Typography>
))`
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  background-color: ${({ color }) => (color ? colors[color] : 'none')};
`;

export const Separator = styled.div`
  width: 18px;
`;
