import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { AppProviders } from 'providers/AppProviders';
import { GlobalStyle } from 'ui/theme/GlobalStyle';
import { App } from 'app/App';

ReactDOM.render(
  <StrictMode>
    <GlobalStyle />
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
  document.getElementById('root'),
);
