import styled, { css } from 'styled-components';

import { ExternalLink } from 'ui/externalLink/ExternalLink';
import { black } from 'ui/theme/colors';
import { Typography } from 'ui/typography/Typography';

export const User = styled.div`
  display: flex;
  align-items: center;
`;

const size = 32;
export const Avatar = styled.div<{ children: string }>`
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;
  min-width: ${size}px;
  min-height: ${size}px;
  font-size: 0;
  margin-right: 10px;
  background-size: contain;
  ${({ children }) =>
    css`
      background-image: url('${children}');
    `}
`;

export const Name = styled(Typography)``;

export const LinkName = styled(ExternalLink)`
  color: ${black};
`;
