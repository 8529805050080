import styled, { createGlobalStyle } from 'styled-components';

import { Page } from 'ui/page/Page';
import { ReactComponent as Image } from 'assets/images/sling.svg';

export const Wrapper = styled(Page)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ErrorImage = styled(Image)`
  max-width: 200px;
  max-height: 200px;
`;

export const PageStyles = createGlobalStyle`
body,html,#root{
height: 100%;
}`;
