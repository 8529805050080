import React from 'react';

import { Color } from 'types/index';

import * as Styled from './Typography.styles';

export type Size = 64 | 32 | 28 | 16 | 14;
export type Weight = 'regular' | 'semiBold';

export type TypographyProps = {
  children: React.ReactNode;
  weight?: Weight;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div' | 'p';
  color?: Color;
  size?: Size;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const Typography = ({
  children,
  tag = 'p',
  weight = 'regular',
  size = 14,
  color = 'black',
  ...props
}: TypographyProps) => {
  return (
    <Styled.Typography as={tag} size={size} weight={weight} color={color} {...props}>
      {children}
    </Styled.Typography>
  );
};
