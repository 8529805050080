import { log } from 'utils/logger';

const ACCESS_TOKEN_KEY = 'accessToken';

const storage = {
  getItem: localStorage.getItem.bind(localStorage),
  setItem: localStorage.setItem.bind(localStorage),
  removeItem: localStorage.removeItem.bind(localStorage),
};

class AuthStorage {
  private _accessToken: string | null = null;

  constructor() {
    try {
      this.accessToken = storage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
      this.accessToken = null;

      log(error, 'error');
    }
  }

  get accessToken(): string | null {
    return this._accessToken;
  }

  set accessToken(value: string | null) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        storage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        storage.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {
      log(error, 'error');
    }
  }
}

export const authStorage = new AuthStorage();
