import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Hero as HeroBase } from 'ui/hero/Hero';

export const Hero = styled(HeroBase)`
  ${down('md')} {
    text-align: center;
  }
`;
