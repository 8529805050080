import { Route, Redirect } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';

import { Loader } from 'ui/loader/Loader';
import { useAuthState } from 'hooks/useAuthState/useAuthState';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRouteProps } from './AuthorizedRoute.types';

export const AuthorizedRoute = (props: AuthorizedRouteProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { accessToken } = useAuthState();

  if (inProgress !== InteractionType.None || (!accessToken && isAuthenticated)) {
    return <Loader />;
  }

  if (accessToken) {
    const decoded = jwtDecode(accessToken as string) as { exp: number };
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= decoded.exp) {
      return <Loader />;
    }
  }

  if (isAuthenticated) {
    return <Route {...props} />;
  }

  return <Redirect to={AppRoute.login} />;
};
