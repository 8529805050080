import styled from 'styled-components';

import { ReactComponent as Image } from 'assets/images/sling.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const size = '75px';
export const Icon = styled(Image)`
  max-width: ${size};
  max-height: ${size};
  margin-right: 20px;
`;
