import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Activities = styled.section`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-row-gap: 10px;
  grid-column-gap: 10px;

  ${up('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${up('md')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${up('lg')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const NoActivitiesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
