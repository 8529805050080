import React from 'react';

import * as colors from 'ui/theme/colors';

import * as Styled from './Button.styles';

export type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  color: keyof typeof colors;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

export const Button = ({ children, ...props }: ButtonProps) => {
  return <Styled.Button {...props}>{children}</Styled.Button>;
};
