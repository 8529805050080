import React from 'react';

import * as Styled from './Card.styles';

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

export const Card = ({ children, className }: CardProps) => <Styled.Card className={className}>{children}</Styled.Card>;
