import React from 'react';
import { useQuery } from 'react-fetching-library';

import { fetchOverallRating } from 'api/actions/activities/ActivitiesActions';
import { SectionLoader } from 'ui/loader/Loader';
import { RequestError } from 'ui/requestError/RequestError';
import { Card } from 'ui/card/Card';
import { Typography } from 'ui/typography/Typography';

export const OverallRating = () => {
  const { payload, error, loading } = useQuery(fetchOverallRating());

  if (loading) {
    return <SectionLoader />;
  }

  if (error || !payload) {
    return <RequestError />;
  }

  return (
    <Card>
      <Typography size={16}>Suma: {payload.data[0].total} pkt</Typography>
      <Typography size={16}>PLN: {payload.data[0].total / 10}</Typography>
    </Card>
  );
};
