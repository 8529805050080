import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { Typography } from 'ui/typography/Typography';
import { Card as CardBase } from 'ui/card/Card';

export const Card = styled(CardBase)``;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

const iconSize = 64;
export const Icon = styled.div<{ color: string }>`
  width: ${iconSize}px;
  height: ${iconSize}px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color }) =>
    css`
      background-color: ${rgba(color, 0.2)};
    `}
`;

export const ActivityName = styled(Typography)`
  margin-left: 16px;
  text-transform: capitalize;
`;

export const Divider = styled.div`
  width: 33%;
  height: 1px;
  background-color: #ebedf9;
  margin: 24px 0;
`;

export const Box = styled.div`
  flex: 1;
`;

export const BoxLabel = styled(Typography)`
  margin-bottom: 10px;
`;
