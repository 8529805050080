import { Route, Redirect } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { AppRoute } from './AppRoute.enum';
import { UnauthorizedRouteProps } from './UnauthorizedRoute.types';

export const UnauthorizedRoute = (props: UnauthorizedRouteProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (!isAuthenticated && inProgress === InteractionType.None) {
    return <Route {...props} />;
  }

  return <Redirect to={AppRoute.home} />;
};
