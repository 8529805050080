import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as Walking } from 'assets/images/activities/walking.svg';
import { ReactComponent as Running } from 'assets/images/activities/running.svg';
import { ReactComponent as Cycling } from 'assets/images/activities/cycling.svg';

import * as Styled from './Loader.styles';

const icons = [Walking, Running, Cycling];

export const Loader = () => {
  const [iconIndex, setIconIndex] = useState(0);
  const Icon = icons[iconIndex];
  const iconRef = useRef<SVGSVGElement | null>(null);
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (iconRef.current) {
      const element = iconRef.current.querySelector('path');

      if (element) {
        setLength(element.getTotalLength());
      }
    }
  }, [iconIndex]);

  const handleIconChange = () => {
    setIconIndex((prev) => (prev + 1 > icons.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const interval = setInterval(handleIconChange, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Styled.Loader length={length}>
      <Icon ref={iconRef} />
    </Styled.Loader>
  );
};

export const SectionLoader = () => (
  <Styled.SectionLoader>
    <Loader />
  </Styled.SectionLoader>
);
