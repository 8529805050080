import React from 'react';
import { useMutation } from 'react-fetching-library';

import { Page } from 'ui/page/Page';
import { Typography } from 'ui/typography/Typography';
import { Separator } from 'ui/separator/Separator';
import { ExternalLink } from 'ui/externalLink/ExternalLink';
import { Button } from 'ui/button/Button';
import homeHero from 'assets/images/hero/home.png';
import { ReactComponent as StravaButton } from 'assets/images/btn_strava_connect.svg';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { reloadMemberActivities } from 'api/actions/activities/ActivitiesActions';

import { BestActivities } from './bestActivities/BestActivities';
import { DivisionsTable } from './DivisionsTable';
import { ParticipantsTable } from './participantsTable/ParticipantsTable';
import { OverallRating } from './overall/OverallRating';
import * as Styled from './Home.styles';

const getStravaLink = () => {
  const link = process.env.REACT_APP_STRAVA_AUTH_URL;

  if (!link) {
    throw new Error('Strava link is not defined!');
  }

  return link.replace('<redirect_uri>', `${window.location.origin}/strava`);
};

export const Home = () => {
  const { user } = useAuthState();
  const { mutate } = useMutation(reloadMemberActivities);

  return (
    <Page>
      <Styled.Hero bg={homeHero}>
        <Typography color="white" size={64} tag="h1">
          TSH Challenge
        </Typography>
        {!user && (
          <ExternalLink href={getStravaLink()}>
            <StravaButton />
          </ExternalLink>
        )}
        {user && (
          <Button
            color="orange"
            onClick={() => {
              mutate(user.stravaId);
              alert('Reload scheduled');
            }}
          >
            Reload My Activities
          </Button>
        )}
      </Styled.Hero>
      <Separator height={80} />
      <Page.Section title="Suma punktów">
        <OverallRating />
      </Page.Section>
      <Separator height={64} />
      <Page.Section title="Najdłuższa aktywność w kategorii">
        <BestActivities />
      </Page.Section>
      <Separator height={64} />
      <Page.Section title="Działy">
        <DivisionsTable />
      </Page.Section>
      <Separator height={78} />
      <Page.Section title="Uczestnicy">
        <ParticipantsTable />
      </Page.Section>
    </Page>
  );
};
