import styled from 'styled-components';

import { ExternalLink } from 'ui/externalLink/ExternalLink';

export const Page = styled.div`
  background-color: #f7fdff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 200px;
  margin: 3rem 3rem;
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #20b7d938;
  border-radius: 10px;
  box-shadow: 5px 9px 10px 0px rgba(0, 0, 0, 0.06);
`;

export const GoogleButton = styled(ExternalLink)`
  width: 100%;
  background-color: #000;
  border: 0;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
`;

export const PoweredBy = styled.div`
  margin-top: 1rem;
  font-size: 0.6rem;
  text-align: center;
`;
