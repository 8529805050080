import React from 'react';

import { Typography } from 'ui/typography/Typography';
import { Separator } from 'ui/separator/Separator';

import * as Styled from './Page.styles';

type PageProps = {
  children: React.ReactNode;
  className?: string;
};

type PageSectionProps = {
  title: string;
  children: React.ReactNode;
};

const PageSection = ({ children, title }: PageSectionProps) => (
  <>
    <Typography size={32} color="black">
      {title}
    </Typography>
    <Separator height={32} />
    {children}
  </>
);

export const Page: React.FC<PageProps> & {
  Section: typeof PageSection;
} = ({ children, className }: PageProps) => <Styled.Page className={className}>{children}</Styled.Page>;

Page.Section = PageSection;
