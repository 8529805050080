import styled from 'styled-components';

export const Hero = styled.div`
  border-radius: 16px;
  width: 100%;
  height: 400px;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position: 50% 50%;
`;
