import { createGlobalStyle } from 'styled-components';

import { pageBackground } from './colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;  
    background-color: ${pageBackground};
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7{
  margin:0
  }
  
  *, *:before, *:after {
  box-sizing: border-box;
}
`;
