import { useMsal } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import { Typography } from 'ui/typography/Typography';
import logo from 'assets/images/tsh.png';

import * as Styled from './Login.styles';

const initializeSignIn = (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
  if (accounts.length === 0) {
    instance.loginRedirect();
  }
};

export const Login = () => {
  const { instance, accounts } = useMsal();
  return (
    <Styled.Page>
      <Styled.Form>
        <Styled.Logo src={logo} alt="tsh-logo" />
        <Styled.GoogleButton onClick={() => initializeSignIn(instance, accounts)}>
          <Typography color="white" weight="semiBold">
            Login
          </Typography>
        </Styled.GoogleButton>
      </Styled.Form>
    </Styled.Page>
  );
};
