import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Page = styled.section`
  width: 100%;
  max-width: 1440px;
  padding: 10px 15px;
  margin: 0 auto;

  ${up('xl')} {
    padding: 57px 108px;
  }
`;
