import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { pageBackground } from 'ui/theme/colors';

export const Wrapper = styled.div`
  ${down('md')} {
    width: 100%;
    overflow-x: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 2px;

  tbody {
    tr {
      background-color: #fff;
    }
  }
`;

export const Head = styled.thead`
  text-align: left;
`;

export const HeadRow = styled.tr``;

export const Header = styled.th`
  padding: 16px 0;
  &:first-of-type {
    position: sticky;
    left: 0;
    background-color: ${pageBackground};
  }

  &:nth-of-type(2) {
    position: sticky;
    left: 40px;
    background-color: ${pageBackground};
  }
`;

export const Body = styled.tbody`
  position: relative;
`;

export const Row = styled.tr``;

export const Cell = styled.td`
  &:first-of-type,
  &:last-of-type {
    padding: 20px 0;
  }

  &:first-of-type {
    padding-left: 24px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  &:nth-of-type(2) {
    padding-left: 24px;
    position: sticky;
    left: 40px;
    background-color: #fff;
  }

  &:last-of-type {
    padding-right: 24px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  padding: 20px 0;
`;

export const NoData = styled.div`
  width: 100%;
  text-align: center;
`;
