import React from 'react';

import * as Styled from './User.styles';

type UserProps = {
  avatar: string;
  name: string;
  stravaId?: string;
};

export const User = ({ name, avatar, stravaId }: UserProps) => {
  const avatarUrl = `https://piesely.tshdev.io/thumbs/profile/${avatar}`;
  const stravaUrl = `https://www.strava.com/athletes/${stravaId}`;

  return (
    <Styled.User>
      <Styled.Avatar>{avatarUrl}</Styled.Avatar>
      {stravaId ? (
        <Styled.LinkName href={stravaUrl} target="_blank">
          {name}
        </Styled.LinkName>
      ) : (
        <Styled.Name>{name}</Styled.Name>
      )}
    </Styled.User>
  );
};
