import { LogLevel } from '@azure/msal-browser';

import { log } from 'utils/logger';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    authority: process.env.REACT_APP_AZURE_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            log(message, 'error');
            return;
          case LogLevel.Warning:
            log(message, 'warn');
            return;
          default:
            return;
        }
      },
    },
  },
};
