import styled, { css } from 'styled-components';

import * as colors from 'ui/theme/colors';
import { Color } from 'types/index';

import { TypographyProps, Size } from './Typography';

const getFontWeight = (weight: TypographyProps['weight']) => {
  switch (weight) {
    case 'regular':
      return 400;
    case 'semiBold':
      return 600;
    default:
      return 400;
  }
};

const getFontSize = (size: Size) => {
  const lineHeightMap: { [key in Size]: number } = {
    '64': 84,
    '32': 40,
    '28': 35,
    '16': 20,
    '14': 17,
  };

  return css`
    font-size: ${size}px;
    line-height: ${lineHeightMap[size]}px;
  `;
};

export const Typography = styled.div`
  ${({ weight, color, size }: TypographyProps & { color: Color; size: Size }) =>
    css`
      font-weight: ${getFontWeight(weight)};
      color: ${colors[color]};
      ${getFontSize(size)}
    `}
`;
