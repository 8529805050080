import React, { useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import decode from 'jwt-decode';
import { useIsAuthenticated } from '@azure/msal-react';

import { fetchCurrentUserAction } from 'api/actions/user/userActions';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { setAuthorized, setUnauthorized } from '../authActionCreators/authActionCreators';

import { UserControllerProps } from './UserController.types';

export const UserController = ({ children }: UserControllerProps) => {
  const { accessToken } = useAuthState();
  const isAuthenticated = useIsAuthenticated();
  const authDispatch = useAuthDispatch();
  const { query: fetchCurrentUser } = useParameterizedQuery(fetchCurrentUserAction);

  useEffect(() => {
    if (accessToken && isAuthenticated) {
      const { unique_name } = decode(accessToken) as { unique_name: string };

      setTimeout(
        () =>
          fetchCurrentUser(unique_name).then(({ payload }) => {
            if (payload) {
              authDispatch(setAuthorized(payload.data));
            } else {
              authDispatch(setUnauthorized());
            }
          }),
        1000,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, isAuthenticated]);

  return <>{children}</>;
};
