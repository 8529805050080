import { QueryResponse, ResponseInterceptor } from 'react-fetching-library';
import { Dispatch } from 'react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { StatusCodes } from 'http-status-codes';

import { Action } from 'api/types';
import { AuthAction } from '../../../context/auth/authReducer/authReducer.types';
import { logout, setTokens } from '../../../context/auth/authActionCreators/authActionCreators';

export const responseRefreshTokenInterceptor: (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  dispatch: Dispatch<AuthAction>,
) => ResponseInterceptor =
  (instance, account, dispatch) =>
  (client) =>
  async (action: Action, response: QueryResponse<Record<string, unknown>>) => {
    function acquireToken() {
      const getTokenOptions = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: account as AccountInfo,
      };
      instance
        .acquireTokenSilent(getTokenOptions)
        .then((response) => {
          dispatch(setTokens(response.accessToken));

          return client.query({
            ...action,
            headers: {
              ...action.headers,
              Authorization: `Bearer ${response.accessToken}`,
            },
          });
        })
        .catch(() => {
          try {
            instance.acquireTokenRedirect(getTokenOptions);
          } catch (e) {
            dispatch(logout());
            instance.setActiveAccount(null);
          }

          return response;
        });
    }

    if (action.config && (action.config.skipAuthorization || action.config.skipRefreshToken)) {
      return response;
    }

    if (response.status === StatusCodes.UNAUTHORIZED) {
      if (account) {
        acquireToken();
      }

      return response;
    }

    return response;
  };
