import React, { useMemo } from 'react';
import { useQuery } from 'react-fetching-library';

import { Typography } from 'ui/typography/Typography';
import { Table, Columns } from 'ui/table/Table';
//import { User } from 'ui/user/User';
import { fetchDepartmentsRating, Department } from 'api/actions/activities/ActivitiesActions';
import { SectionLoader } from 'ui/loader/Loader';
import { RequestError } from 'ui/requestError/RequestError';
import { departmentMapper } from 'constants/departmentMapper';

export const DivisionsTable = () => {
  const { payload, loading, error } = useQuery(fetchDepartmentsRating());

  const columns: Columns<Department> = useMemo(
    () => [
      {
        Header: 'Dział',
        accessor: 'department',
        Cell: ({ value }) => (
          <Typography size={16} weight="semiBold">
            {departmentMapper[value] || value}
          </Typography>
        ),
      },
      {
        Header: 'Suma punktów',
        accessor: 'points',
        Cell: ({ value }) => <Typography size={16}>{value}</Typography>,
      },
      {
        Header: 'Aktywni uczestnicy',
        accessor: 'members',
        Cell: ({ value }) => <Typography size={16}>{value}</Typography>,
      },
      {
        Header: 'Średnia punktów',
        accessor: 'averagePoints',
        Cell: ({ value }) => <Typography size={16}>{value}</Typography>,
      },
      //{
      //  Header: 'Lider',
      //  accessor: 'lider',
      //  Cell: ({ value: { name, avatar } }) => {
      //    return <User avatar={avatar} name={name} />;
      //  },
      //},
    ],
    [],
  );

  if (loading) {
    return <SectionLoader />;
  }

  if (error || !payload) {
    return <RequestError />;
  }

  return <Table columns={columns} data={payload.data} />;
};
