import React from 'react';

import * as Styled from './Hero.styles';

export type HeroProps = {
  children?: React.ReactNode;
  bg: string;
  className?: string;
};

export const Hero = ({ children, bg, className }: HeroProps) => {
  return (
    <Styled.Hero style={{ backgroundImage: `url('${bg}')` }} className={className}>
      {children}
    </Styled.Hero>
  );
};
