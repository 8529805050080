import React from 'react';
import { useQuery } from 'react-fetching-library';

import { ActivityCard } from 'ui/activityCard/ActivityCard';
import { fetchBestActivities, Activity } from 'api/actions/activities/ActivitiesActions';
import { SectionLoader } from 'ui/loader/Loader';
import { RequestError } from 'ui/requestError/RequestError';

import * as Styled from './BestActivities.styles';

const activityValueFormatter = (activity: Activity) => {
  if (activity.distance) {
    return activity.distance.toString();
  }

  return (activity.duration / (60 * 60)).toFixed(1);
};

export const BestActivities = () => {
  const { payload, error, loading } = useQuery(fetchBestActivities());

  if (loading) {
    return <SectionLoader />;
  }

  if (error || !payload) {
    return <RequestError />;
  }

  return (
    <Styled.Activities>
      {payload.data.map((activity: Activity) => {
        const value = activityValueFormatter(activity);

        return (
          <ActivityCard
            key={activity.id}
            activity={activity.type}
            value={value}
            member={activity.member}
            avatar={activity.picturePath}
          />
        );
      })}

      {payload.data.length === 0 && <Styled.NoActivitiesWrapper>Brak aktywności</Styled.NoActivitiesWrapper>}
    </Styled.Activities>
  );
};
