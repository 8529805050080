import { Member } from 'api/actions/activities/ActivitiesActions';

export const departmentMapper: Partial<{ [key in Member['department']]: string }> = {
  TEST: 'QA',
  DEVOPS: 'DevOps',
  NODE: 'Node',
  FRONT: 'Frontend',
  ADMIN: 'Administracja',
  MARKETIN: 'Marketing',
  MANAGE: 'Management',
  SALES: 'Sprzedaż',
  NMD: 'Design',
  BOARD: 'Board',
  ANDROID: 'Mobile',
  RP: 'Prawnicy',
};
