import { useAccount, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { InteractionType } from '@azure/msal-browser';

import { logout, setTokens } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { AppRoutes } from 'routing/AppRoutes';

export const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const authDispatch = useAuthDispatch();

  useEffect(() => {
    if (account && inProgress === InteractionType.None) {
      const getTokenOptions = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account,
      };
      instance
        .acquireTokenSilent(getTokenOptions)
        .then((response) => {
          if (response) {
            authDispatch(setTokens(response.accessToken));
          }
        })
        .catch(() => {
          try {
            instance.acquireTokenRedirect(getTokenOptions);
          } catch (e) {
            authDispatch(logout());
            instance.setActiveAccount(null);
          }
        });
    }
  }, [account, instance, inProgress, authDispatch]);

  return <AppRoutes />;
};
