import React from 'react';

import { Typography } from 'ui/typography/Typography';
import { Separator } from 'ui/separator/Separator';
import { Button } from 'ui/button/Button';

import * as Styled from './RequestError.styles';

export const RequestError = () => {
  return (
    <Styled.Wrapper>
      <Styled.Icon />
      <div>
        <Typography size={16}>Uppppsss..</Typography>
        <Typography size={16}>Coś poszło nie tak</Typography>
        <Separator height={10} />
        <Button color="black" onClick={() => window.location.replace('/')}>
          <Typography color="white">Przeładuj stronę</Typography>
        </Button>
      </div>
    </Styled.Wrapper>
  );
};
