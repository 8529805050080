import React, { useMemo } from 'react';
import { useQuery } from 'react-fetching-library';
import { Cell } from 'react-table';

import { Typography } from 'ui/typography/Typography';
import { Table, Columns } from 'ui/table/Table';
import { User } from 'ui/user/User';
import { fetchMembersRating, Member } from 'api/actions/activities/ActivitiesActions';
import { SectionLoader } from 'ui/loader/Loader';
import { RequestError } from 'ui/requestError/RequestError';
import { departmentMapper } from 'constants/departmentMapper';
import { isPrimaAprilis } from 'utils/isPrimaAprilis';

import * as Styled from './ParticipantsTable.styles';

const detailsColumns = (accessor: 'walking' | 'cycling' | 'running' | 'indoor') => [
  { Header: 'Kilometry', accessor: `${accessor}Distance` },
  {
    Header: 'Punkty',
    accessor: `${accessor}Points`,
    Cell: ({ value }: Cell<Member>) => (
      <Typography size={16} color="gray">
        {value}
      </Typography>
    ),
  },
];

export const ParticipantsTable = () => {
  const { loading, payload, error } = useQuery(fetchMembersRating());

  const columns: Columns<Member> = useMemo(
    () => [
      {
        Header: '',
        id: 'lp',
        Cell: ({ row }: Cell<Member>) => <Typography size={16}>{row.index + 1}</Typography>,
      },
      {
        Header: 'Imię i nazwisko',
        id: 'user',
        Cell: ({
          row: {
            original: { member, picturePath, stravaId },
          },
        }: Cell<Member>) => <User avatar={picturePath} name={member} stravaId={stravaId} />,
      },
      {
        Header: 'Dział',
        accessor: 'department',
        Cell: ({ value }) => (
          <Typography size={16} weight="semiBold">
            {departmentMapper[value] || value}
          </Typography>
        ),
      },
      {
        id: 'walking',
        Header: () => (
          <Styled.HeaderLabel color="violet">{isPrimaAprilis() ? `Code Lines` : `Walking`}</Styled.HeaderLabel>
        ),
        columns: detailsColumns('walking'),
      },
      {
        id: 'separator1',
        Cell: () => <Styled.Separator />,
      },
      {
        Header: () => (
          <Styled.HeaderLabel color="blue">{isPrimaAprilis() ? `Key Strokes` : `Running`}</Styled.HeaderLabel>
        ),
        id: 'running',
        columns: detailsColumns('running'),
      },
      {
        id: 'separator2',
        Cell: () => <Styled.Separator />,
      },
      {
        Header: () => (
          <Styled.HeaderLabel color="orange">{isPrimaAprilis() ? `Code Reviews` : `Cycling`}</Styled.HeaderLabel>
        ),
        id: 'cycling',
        columns: detailsColumns('cycling'),
      },
      {
        id: 'separator3',
        Cell: () => <Styled.Separator />,
      },
      {
        Header: () => <Styled.HeaderLabel color="red">{isPrimaAprilis() ? `Power Naps` : `Other`}</Styled.HeaderLabel>,
        id: 'indoor',
        columns: [
          {
            Header: 'Czas (h)',
            accessor: 'indoorDuration',
            Cell: ({ value }) => (
              <Typography size={16} color="gray">
                {(value / (60 * 60)).toFixed(1)}
              </Typography>
            ),
          },
          {
            Header: 'Punkty',
            accessor: 'indoorPoints',
            Cell: ({ value }) => (
              <Typography size={16} color="gray">
                {value}
              </Typography>
            ),
          },
        ],
      },
      {
        Header: 'Suma',
        accessor: 'total',
      },
    ],
    [],
  );

  if (loading) {
    return <SectionLoader />;
  }

  if (error || !payload) {
    return <RequestError />;
  }

  return <Table columns={columns} data={payload.data} />;
};
