import styled from 'styled-components';
import { lighten } from 'polished';

import * as colors from 'ui/theme/colors';

import { ButtonProps } from './Button';

export const Button = styled.button<ButtonProps>`
  border: 0;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ color }) => colors[color]};
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: ${({ color }) => lighten(10, colors[color])};
  }
`;
