import React from 'react';

import { Typography } from 'ui/typography/Typography';
import { User } from 'ui/user/User';
import { orange, blue, violet, red } from 'ui/theme/colors';
import { ReactComponent as Walking } from 'assets/images/activities/walking.svg';
import { ReactComponent as Running } from 'assets/images/activities/running.svg';
import { ReactComponent as Cycling } from 'assets/images/activities/cycling.svg';
import { ReactComponent as Workout } from 'assets/images/activities/workout.svg';
import { Activity } from 'api/actions/activities/ActivitiesActions';

import * as Styled from './ActivityCard.styles';

type Unit = 'km' | 'h';

const activityMap: {
  [key in Activity['type']]: {
    icon: React.ComponentType;
    unit: Unit;
    color: string;
  };
} = {
  Walk: {
    color: violet,
    icon: Walking,
    unit: 'km',
  },
  Run: {
    color: blue,
    icon: Running,
    unit: 'km',
  },
  Ride: {
    color: orange,
    icon: Cycling,
    unit: 'km',
  },
  Hike: {
    color: orange,
    icon: Cycling,
    unit: 'km',
  },
  Workout: {
    color: red,
    icon: Workout,
    unit: 'h',
  },
};

const activityFallback = {
  color: violet,
  icon: Walking,
  unit: 'km',
};

type ActivityCardProps = {
  activity: Activity['type'];
  value: string;
  avatar: string;
  member: string;
};

export const ActivityCard = ({ activity, value, member, avatar }: ActivityCardProps) => {
  const { color, unit, icon: Icon } = activityMap[activity] || activityFallback;

  return (
    <Styled.Card>
      <Styled.Row>
        <Styled.Icon color={color}>
          <Icon />
        </Styled.Icon>
        <Styled.ActivityName size={16} weight="semiBold">
          {activity}
        </Styled.ActivityName>
      </Styled.Row>
      <Styled.Divider />
      <Styled.Row>
        <Styled.Box>
          <Styled.BoxLabel size={14} weight="semiBold" color="gray">
            {activity === 'Workout' ? 'Najdłuższy czas' : 'Najdłuższy dystans'}
          </Styled.BoxLabel>
          <Typography size={28}>{`${value} ${unit}`}</Typography>
        </Styled.Box>
        <Styled.Box>
          <Styled.BoxLabel size={14} weight="semiBold" color="gray">
            Uczestnik
          </Styled.BoxLabel>
          <User avatar={avatar} name={member} />
        </Styled.Box>
      </Styled.Row>
    </Styled.Card>
  );
};
