import React, { Component, ErrorInfo } from 'react';
//import * as Sentry from '@sentry/browser';

import { Typography } from 'ui/typography/Typography';
import { Separator } from 'ui/separator/Separator';
import { Button } from 'ui/button/Button';

import * as Styled from './ErrorBoundary.styles';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //if (process.env.REACT_APP_SENTRY_DSN) {
    //  Sentry.withScope(scope => {
    //    scope.setExtras(errorInfo);
    //    Sentry.captureException(error);
    //  });
    //}
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <>
          <Styled.PageStyles />
          <Styled.Wrapper>
            <Styled.ErrorImage />
            <div>
              <Typography size={28}>Uppppsss..</Typography>
              <Separator height={10} />
              <Typography size={28}>Coś poszło nie tak, skontaktuj się z administartorem</Typography>
              <Separator height={10} />
              <Button color="black" onClick={() => window.location.replace('/')}>
                <Typography color="white">wróc do strony głównej</Typography>
              </Button>
            </div>
          </Styled.Wrapper>
        </>
      );
    }

    return children;
  }
}
