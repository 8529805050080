import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { UserController } from 'context/auth/userController/UserController';
import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { ErrorBoundary } from 'app/errorBoundary/ErrorBoundary';
import { msalConfig } from 'msalConfig';

import { AppProvidersProps } from './AppProviders.types';

const pca = new PublicClientApplication(msalConfig);

export const AppProviders = ({ children }: AppProvidersProps) => (
  <MsalProvider instance={pca}>
    <ErrorBoundary>
      <Router>
        <AuthContextController>
          <ClientContextController>
            <UserController>{children}</UserController>
          </ClientContextController>
        </AuthContextController>
      </Router>
    </ErrorBoundary>
  </MsalProvider>
);
