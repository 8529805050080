import styled, { keyframes } from 'styled-components';

import { pageBackground } from 'ui/theme/colors';

const animation = (length: number) => keyframes`{
      from {
        stroke-dashoffset: ${length};
      }
      to {
        stroke-dashoffset: 0;
      }
    }`;

export const Loader = styled.div<{ length: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${pageBackground};

  & > svg {
    width: 150px;
    height: 150px;
    stroke-dasharray: 20;
    stroke-dashoffset: ${({ length }) => length};
    animation: ${({ length }) => animation(length)} 4s linear;
  }
`;

export const SectionLoader = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`;
