import { Action } from 'api/types';

import { FetchCurrentUserResponse } from './userActions.types';

export function fetchCurrentUserAction(email: string): Action<FetchCurrentUserResponse> {
  return {
    method: 'GET',
    endpoint: `/me?email=${email}`,
  };
}
