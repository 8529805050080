import React from 'react';
import { useTable, Column as TableColumn, useSortBy } from 'react-table';

import { Typography } from 'ui/typography/Typography';

import * as Styled from './Table.styles';

export type Column<Data extends Record<string, unknown> = Record<string, unknown>> = TableColumn<Data>;

export type Columns<Data extends Record<string, unknown> = Record<string, unknown>> = Array<Column<Data>>;

export type TableProps<Data extends Record<string, unknown> = Record<string, unknown>> = {
  columns: Columns<Data>;
  data: Data[];
  globalSearch?: boolean;
  sortable?: boolean;
  pagination?: boolean;
  rowsPerPage?: number;
  initialPage?: number;
  loading?: boolean;
  loadingRows?: number;
};

export function Table<Data extends Record<string, unknown> = Record<string, unknown>>({
  columns,
  data,
}: TableProps<Data>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<Data>(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const noResults = rows.length === 0;

  return (
    <Styled.Wrapper>
      <Styled.Table {...getTableProps()}>
        <Styled.Head>
          {headerGroups.map((headerGroup, row) => (
            <Styled.HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Styled.Header
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      title: column.className,
                    }),
                  )}
                >
                  <Typography tag="span" size={14} weight="semiBold" color="gray">
                    {column.render('Header')}

                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </Typography>
                </Styled.Header>
              ))}
            </Styled.HeadRow>
          ))}
        </Styled.Head>
        <Styled.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <Styled.Row {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Styled.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Styled.Cell>
                ))}
              </Styled.Row>
            );
          })}

          {noResults && (
            <Styled.Row>
              <Styled.Cell colSpan={100}>
                <Styled.NoData>Brak danych</Styled.NoData>
              </Styled.Cell>
            </Styled.Row>
          )}
        </Styled.Body>
      </Styled.Table>
    </Styled.Wrapper>
  );
}
