import { Action } from 'api/types';

type DepartmentType =
  | 'PHP'
  | 'TEST'
  | 'DEVOPS'
  | 'NODE'
  | 'FRONT'
  | 'PM'
  | 'ADMIN'
  | 'MARKETIN'
  | 'MANAGE'
  | 'SALES'
  | 'NMD'
  | 'BOARD'
  | 'ANDROID'
  | 'HR'
  | 'RP';

export type Activity = {
  distance: number;
  duration: number;
  id: string;
  member: string;
  memberEmail: string;
  picturePath: string;
  type: 'Walk' | 'Ride' | 'Hike' | 'Workout' | 'Run';
};

type FetchBestActivitiesResponse = {
  success: boolean;
  data: Activity[];
};

export const fetchBestActivities = (): Action<FetchBestActivitiesResponse> => {
  return {
    method: 'GET',
    endpoint: `/best-activities`,
  };
};

export type Department = {
  department: DepartmentType;
  points: number;
  averagePoints: number;
  members: number;
};

type FetchDepartmentsRatingResponse = {
  success: boolean;
  data: Department[];
};

export const fetchDepartmentsRating = (): Action<FetchDepartmentsRatingResponse> => {
  return {
    method: 'GET',
    endpoint: `/departments-rating`,
  };
};

export type Member = {
  id: string;
  department: DepartmentType;
  member: string;
  memberEmail: string;
  picturePath: string;
  walkingDistance: number;
  walkingPoints: number;
  runningDistance: number;
  runningPoints: number;
  cyclingDistance: number;
  cyclingPoints: number;
  indoorDuration: number;
  indoorPoints: number;
  total: number;
  stravaId: string;
};

type FetchMembersRatingResponse = {
  success: boolean;
  data: Member[];
};

export const fetchMembersRating = (): Action<FetchMembersRatingResponse> => {
  return {
    method: 'GET',
    endpoint: `/members-rating`,
  };
};

type BasicStepResponse = {
  startDate: string;
};

export const fetchOverallRating = (): Action<OverallRatingResponse> => {
  return {
    method: 'GET',
    endpoint: `/overall-rating`,
  };
};

type OverallRating = {
  total: number;
};

type OverallRatingResponse = {
  data: OverallRating[];
};

export const reloadMemberActivities = (stravaId: string): Action<BasicStepResponse> => {
  return {
    method: 'POST',
    endpoint: `/activities`,
    body: {
      stravaId,
    },
  };
};
